'use strict';

var processInclude = require('./util');

$(document).ready(function () {
    processInclude(require('./search/searchCategories'));
    processInclude(require('./search/searchAttributes'));
    processInclude(require('./search/searchBrands'));
    processInclude(require('./product/quickView'));
});
