'use strict';

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });

    updateDom($results, '.refinements-attributes');
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements-attributes': handleRefinements
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar',
        '.sorting-rules',
        '#sidebarRefinementsContainer'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });
}

/**
 * appends params to a url
 * @param {string} selectedFilter - filter that was clicked on the dropdown filter
 */
function activeFilters(selectedFilter) {
    var type = $(selectedFilter).closest('.refinement').attr('id');
    $('#' + type + ' button').each(function () {
        if ($(this).find('i').hasClass('fa-check-square')) {
            $('a[href="#' + type + '"] .js-active-filters-mobile')
                .append('<span data-id="' + $(this).data('id') + '" class="text-grey mx-1 mb-2">' + $(this)
                    .find('span:not(.sr-only)').text() + '</span>');
        }
    });
    $('.collapse#' + type).addClass('show');
    $('a[href="#' + type + '"] ').find('.fa-chevron-down').addClass('fa-rotate-180');
}

/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @return {undefined}
 */
function getContent($element, $target) {
    var showMoreUrl = $element.data('url');
    $.spinner().start();
    $.ajax({
        url: showMoreUrl,
        method: 'GET',
        success: function (response) {
            $target.append(response);
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response) {
    var $tempDom = $('<div>').append($(response));
    var sortOptions = $tempDom.find('.grid-footer').data('sort-options').options;
    sortOptions.forEach(function (option) {
        $('option.' + option.id).val(option.url);
    });
}

/**
 * Shows the description of the banner,
 * If the description is longer then the image,
 * The description is cut off
 */
function showDescription() {
    if ($('.category-slot-banner').css('display') !== 'none' && $('.description-wrapper').height() > $('.category-slot-banner img').height()) {
        $('.description-wrapper').css({ overflow: 'hidden', maxHeight: $('.category-slot-banner img').height() - 16.67 });
        $('.description-wrapper p').css({ overflow: 'hidden', maxHeight: 21.0 * Math.floor(($('.category-slot-banner img').height() - 16.67 - 38) / 21.0) });
        $('a.more').show();
    } else if ($('.bannerImage').css('display') !== 'none' && $('.description-wrapper').height() > $('.bannerImage img').height()) {
        $('.description-wrapper').css({ overflow: 'hidden', maxHeight: $('.bannerImage img').height() - 16.67 });
        $('.description-wrapper p').css({ overflow: 'hidden', maxHeight: 21.0 * Math.floor(($('.bannerImage img').height() - 16.67 - 38) / 21.0) });
        $('a.more').show();
    }
    $('#descriptionId .description-open').removeClass('description-open').addClass('description-closed');
}

module.exports = {
    filter: function () {
        // Display refinements bar when Menu icon clicked
        $('.container').on('click', 'button.filter-results', function () {
            $('.refinement-bar, .modal-background').show();
            $('.refinement-bar').siblings().attr('aria-hidden', true);
            $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', true);
            $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', true);
            $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', true);
            $('.refinement-bar .close').focus();
        });
    },

    closeRefinements: function () {
        // Refinements close button
        $('.container').on('click', '.refinement-bar button.close, .refinement-bar .btn.js-close, .modal-background', function () {
            $('.refinement-bar, .modal-background').hide();
            $('.refinement-bar').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', false);
            $('.btn.filter-results').focus();
        });
    },

    resize: function () {
        // Close refinement bar and hide modal background if user resizes browser
        $(window).resize(function () {
            $('.refinement-bar, .modal-background').hide();
            $('.refinement-bar').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', false);
            $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', false);
        });
    },

    sort: function () {
        // Handle sort order menu selection
        $('.container').on('change', '[name=sort-order]', function (e) {
            e.preventDefault();

            $.spinner().start();
            $(this).trigger('search:sort', this.value);
            $.ajax({
                url: this.value,
                data: { selectedUrl: this.value },
                method: 'GET',
                success: function (response) {
                    $('.product-grid').empty().html(response);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    showMore: function () {
        // Show more products
        let newUrl;
        $('.container').on('click', '.show-more button', function (e) {
            e.stopPropagation();
            var showMoreUrl = $(this).data('url');

            e.preventDefault();

            $.spinner().start();
            $(this).trigger('search:showMore', e);
            $.ajax({
                url: showMoreUrl,
                data: { selectedUrl: showMoreUrl },
                method: 'GET',
                success: function (response) {
                    $('.grid-footer').replaceWith(response);
                    updateSortOptions(response);
                    $.spinner().stop();

                    // added window history to direcht user to same products on PLP after clicking back on PDP
                    let searchParams = new URLSearchParams(window.location.search);
                    let productsOnPage = 24;
                    if (searchParams.has('sz')) {
                        productsOnPage = parseInt(searchParams.get('sz'), 10) + 12;
                    }
                    searchParams.set('sz', productsOnPage);
                    newUrl = window.location.pathname + '?' + searchParams.toString();
                    window.history.pushState(null, null, newUrl);
                    // end window history

                    var $wishlistCounter = $('.js-wishlistCount');
                    var products = $wishlistCounter.data('products');
                    if (products) {
                        if (typeof products === 'string') {
                            products = JSON.parse(products);
                        }

                        products.forEach(function (productSku) {
                            $(`.js-wishlistTile[data-pid="${productSku}"]:not(.active)`).addClass('active').find('i').toggleClass('d-none');
                        });
                    }
                    var tooltipInit = require('../components/tooltipInit');
                    tooltipInit.tooltipRun();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    applyFilter: function () {
        // Handle refinement value selection and reset click
        $('.container').on(
            'click',
            '.refinements-categories li button, .refinement-bar button.reset, .filter-value button, .swatch-filter button',
            function (e) {
                e.preventDefault();
                e.stopPropagation();

                $.spinner().start();
                $(this).trigger('search:filter', e);
                var selectedFilter = $(this);
                $.ajax({
                    url: $(this).data('href'),
                    data: {
                        page: $('.grid-footer').data('page-number'),
                        selectedUrl: $(this).data('href')
                    },
                    method: 'GET',
                    success: function (response) {
                        $('.refinements-categories li button, .refinement-bar button.reset').removeClass('font-weight-bold');
                        selectedFilter.addClass('font-weight-bold');
                        parseResults(response);
                        $.spinner().stop();
                        activeFilters(selectedFilter);
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            });
    },

    showContentTab: function () {
        // Display content results from the search
        $('.container').on('click', '.content-search', function () {
            if ($('#content-search-results').html() === '') {
                getContent($(this), $('#content-search-results'));
            }
        });

        // Display the next page of content results from the search
        $('.container').on('click', '.show-more-content button', function () {
            getContent($(this), $('#content-search-results'));
            $('.show-more-content').remove();
        });
    },

    loadCategoryBanner: function () {
        var url = $('.category-slot-banner').data('href');
        if (url) {
            $.ajax({
                url: url,
                method: 'GET',
                success: function (response) {
                    // eslint-disable-next-line no-useless-escape
                    var slotHtml = response.replace(/<\!--.*?-->/g, '');
                    if (/\S/.test(slotHtml)) {
                        $('.category-slot-banner').empty().html(slotHtml);
                        var contentImages = $('.category-slot-banner img');
                        var totalImages = contentImages.length;
                        var loadedImages = 0;
                        contentImages.each(function () {
                            $(this).on('load', function () {
                                loadedImages++;
                                if (loadedImages === totalImages) {
                                    $('.category-slot-banner').show('', showDescription);
                                }
                            });
                        });
                    } else if ($('#bannerImage').length !== 0) {
                        $('#bannerImage').show('', showDescription);
                    } else {
                        $('#descriptionId').removeClass('col-md-4');
                        $('.category-slot-banner').removeClass('col-md-8');
                    }

                    if ($('#descriptionId')) {
                        $('#descriptionId').show();
                    }

                    if ($('.description-wrapper')[0].scrollHeight > $('.description-wrapper').height()) {
                        $('a.more').show();
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    },
    expandBannerText: function () {
        $('.container').on('click', 'a.more', function (e) {
            e.preventDefault();
            $('.description-wrapper').css({ overflow: 'visible', maxHeight: '100%' });
            $('.description-wrapper p').css({ overflow: 'visible', maxHeight: '100%' });
            $('.description-closed').removeClass('description-closed').addClass('description-open');
            $('a.less').show();
            $('a.more').hide();
            return false;
        });

        $('.container').on('click', 'a.less', function (e) {
            e.preventDefault();
            if ($('.category-slot-banner').css('display') !== 'none') {
                $('.description-wrapper').css({ overflow: 'hidden', maxHeight: $('.category-slot-banner img').height() - 16.67 });
                $('.description-wrapper p').css({ overflow: 'hidden', maxHeight: 21.0 * Math.floor(($('.category-slot-banner img').height() - 16.67 - 38) / 21.0) });
                $('.description-open').removeClass('description-open').addClass('description-closed');
            } else if ($('.bannerImage').css('display') !== 'none') {
                $('.description-wrapper').css({ overflow: 'hidden', maxHeight: $('.bannerImage img').height() - 16.67 });
                $('.description-wrapper p').css({ overflow: 'hidden', maxHeight: 21.0 * Math.floor(($('.bannerImage img').height() - 16.67 - 38) / 21.0) });
                $('.description-open').removeClass('description-open').addClass('description-closed');
            }
            $('a.more').show();
            $('a.less').hide();
            return false;
        });
    }
};
