'use strict';

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    var htmlToUpdate = $updates.html();
    if (htmlToUpdate) {
        $(selector).empty().html(htmlToUpdate);
    }
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });

    updateDom($results, '.refinements-attributes');
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements-attributes': handleRefinements
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar',
        '.refinement-bar',
        '.sorting-rules'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });
}

/**
 * appends params to a url
 * @param {string} selectedFilter - filter that was clicked on the dropdown filter
 */
function activeFilters(selectedFilter) {
    var filterCheck = true;
    $('.js-active-filters span[data-id="' + selectedFilter.data('id') + '"]').remove();
    if (selectedFilter.find('i').hasClass('fa-square')) {
        $('.js-active-filters span').each(function () {
            if ($(this).data('id') !== $(selectedFilter).data('id')) {
                filterCheck = true;
            } else {
                filterCheck = false;
            }
        });
        if (filterCheck) {
            $('.js-active-filters')
                .append('<span data-id="' + $(selectedFilter).data('id') + '" class="btn btn-grey btn-sm mr-2 mb-2">' + $(selectedFilter)
                    .find('span:not(.sr-only)').text() + '<i class="fas fa-times ml-2"></i></span>');
        }
    }
}

$('body').on('click', '.js-active-filters span', function () {
    $('.dropdown-menu button[data-id="' + $(this).data('id') + '"]').click();
    $(this).remove();
});

module.exports = {
    applyFilter: function () {
        // Handle refinement value selection and reset click
        $('.container').on(
            'click',
            '.refinements-attributes li button, .refinement-bar button.reset, .filter-value button, .swatch-filter button',
            function (e) {
                e.preventDefault();
                e.stopPropagation();

                $.spinner().start();
                $(this).trigger('search:filter', e);
                var selectedFilter = $(this);
                $.ajax({
                    url: $(this).data('href'),
                    data: {
                        page: $('.grid-footer').data('page-number'),
                        selectedUrl: $(this).data('href')
                    },
                    method: 'GET',
                    success: function (response) {
                        parseResults(response);
                        $.spinner().stop();
                        activeFilters(selectedFilter);
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            });
    }
};
