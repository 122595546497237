'use strict';

$(document).ready(function () {
    $('body').on('keyup', '.search-filter', function () {
        var value = $(this).val().toLowerCase();
        $(this).closest('.dropdown-menu, .refinement').find('li').filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
            return true;
        });
    });
    $('.dropdown-menu li:not(.brand)').closest('.dropdown-menu').find('.search-filter').remove();
});
